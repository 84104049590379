import React from 'react'
import {
  Main,
  Container,
  Content,
  HighlightBoard,
  H,
  TextBlock,
} from './styles'

export default function About() {
  return (
    <Main>
      <Container>
        <h1>Sobre nós</h1>
        <Content>
          <HighlightBoard>
            Soluções
            <br />
            <H>acessíveis</H> e
            <br />
            <H>inovadoras</H>
            <br />
            desde 2008
          </HighlightBoard>
          <TextBlock>
            <p>
              A Lelis Engenharia é uma empresa de engenharia focada na
              satisfação dos nossos clientes, buscando atender suas necessidades
              particulares, provendo soluções em projetos, perícias, patologias,
              avaliação técnica de projeto, gerenciamento de projeto e obra,
              desenvolvimento de novas tecnologias e consultoria, visando sempre
              a aplicação de tecnologias.
            </p>
            <p>
              Nossa vocação é ser o elo tecnológico da cadeia de valor de nossos
              clientes onde fornecemos soluções “turn key” abrangendo
              fornecimento de produtos, serviços e conhecimentos técnicos
              especializados. Nossa principal diferença reside na competência
              para encontrar a melhor solução para as necessidades específicas
              de forma personalizada em busca do melhor custo benefício.
            </p>
          </TextBlock>
        </Content>
      </Container>
    </Main>
  )
}
