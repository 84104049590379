import styled from '@emotion/styled'
import spacings from '../../../config/spacings'
import constants from '../../../config/constants'
import { sheets } from '../../../config/typography'
import colors from '../../../config/colors'

import { TextBlock as TB } from '../../core/styles'

export const Main = styled.main``

export const Container = styled.section`
  max-width: ${constants.sizes.maxWidth}px;

  padding: ${spacings.large}rem;
  margin: 0 auto;
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: ${colors.light.text};

  & > * {
    padding: 0 ${spacings.large}rem;
  }

  @media only screen and (max-width: ${constants.sizes.medium}px) {
    flex-direction: column;
    align-items: center;
  }
`

export const HighlightBoard = styled.span`
  ${sheets.headers.largest};
  font-weight: 500;
  min-width: 28rem;
`

export const H = styled.span`
  color: ${colors.core.primary};
`

export const TextBlock = styled(TB)`
  max-width: 46rem;

  @media only screen and (max-width: ${constants.sizes.medium}px) {
    max-width: none;
  }
`
