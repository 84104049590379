import React from 'react'
import DefaultMaster from '../components/masters/DefaultMaster/DefaultMaster'
import About from '../components/containers/About'

function AboutPage() {
  return (
    <DefaultMaster title="Sobre">
      <About />
    </DefaultMaster>
  )
}

export default AboutPage
